// パスワードの最低文字数
export const PASSWORD_MIN_LENGTH = 8;

// 性別
export const GENDER = {
  UNSELECTED: 0,
  MALE: 1,
  FEMALE: 2,
};

// ラベル種別
export const LABEL_TYPES = {
  patient: {
    label: "患者",
    color: " pink darken-1",
    textColor: "white",
  },
  doctor: {
    label: "医師",
    color: "indigo darken-1",
    textColor: "white",
  },
  family: {
    label: "家族",
    color: "green darken-1",
    textColor: "white",
  },
  familyDoctor: {
    label: "かかりつけ医",
    color: "blue darken-1",
    textColor: "white",
  },
  nurse: {
    label: "看護師",
    color: "pink accent-1",
    textColor: "white",
  },
  careManager: {
    label: "ケアマネージャー",
    color: "teal  darken-1",
    textColor: "white",
  },
  careWorker: {
    label: "介護士",
    color: "cyan darken-1",
    textColor: "white",
  },
  other: {
    label: "その他",
    color: "grey",
    textColor: "white",
  },
};

// 種別に対応するラベル種別
export const TYPE_TO_LABEL_TYPES = {
  patient: ["patient"],
  doctor: ["doctor"],
  family: ["family", "familyDoctor", "nurse", "careManager", "careWorker", "other"],
};

// 患者記録の最小管理対象日付
export const MIN_DATE = "20140101";

// スナックバータイムアウト値
export const SNACKBAR_TIMEOUT = 5000;
